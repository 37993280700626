<template>
  <b-form-group>
    <label>{{label}}</label> <v-icon v-if="info" name="info-circle" v-bind:id="name" color="orange" v-b-tooltip.hover.right.v-warning="`${info}`"/>
    <b-form-input
      :ref="`ref${name}`"
      :type="type"
      :size="size"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :state="verificaEstadoInput()"
      :min="valMin"
      :max="valMax"
      :number="number"
      v-model="valor"
      @input="onStateInput($event, 'onInput')"
      @change="onStateInput($event, 'onChange')"
      @update="onStateInput($event, 'onUpdate')"
      @blur="$emit('blur')"
      autocomplete="off" />
    <b-form-invalid-feedback>
      {{ retornaMensagemDeErro() }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue"
import { BFormInput } from "bootstrap-vue"
import { BFormInvalidFeedback } from "bootstrap-vue"
import InputBase from "../inputs/mixins/InputBase"

export default {
  name: "InputBasico",
  mixins: [ InputBase ],
  props:['info'],
  components: {
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback
  },
};
</script>
