import { retornaStringParaYYYYMMDD } from '@/common/helpers/DataHelper'

const inputBase = {
  $_veeValidate: {
    name() {
      return this.label;
    },
    value() {
      return this.value;
    }
  },
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    placeholder: {
      type: String,
      default: '',
    },
    scope: {
      type: String,
      default: '',
    },
    errorsBag: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    lowerCase: {
      type: Boolean,
      default: false,
    },
    upperCase: {
      type: Boolean,
      default: false,
    },
    valMin: {
      type: Number,
      default: 0,
    },
    valMax: {
      type: Number,
    },
    number: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number
    },
    mostrarRemover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valor: {
      get() {
        if (this.value && this.type == 'date') {
          return retornaStringParaYYYYMMDD(this.value)
        }

        return this.value
      },
      set(val) {
        if (val != this.value) {
          if (this.lowerCase) {
            this.$emit("input", val.toLowerCase())
          } else if (this.upperCase) {
            this.$emit("input", val.toUpperCase())
          } else {
            this.$emit("input", val)
          }
        }
      }
    }
  },
  methods: {
    onStateInput(evt, evento) {
      this.$emit(evento, evt)
    },
    focar() {
      setTimeout(() => {
        this.$refs[`ref${this.name}`].$el.focus()
      }, 1)
    },
    nomeCampoErro() {
      return this.scope !== '' ? `${this.scope}.${this.label}` : this.label
    },
    verificaEstadoInput() {
      if (!this.errorsBag) {
        return null
      }
      // false = Com erro
      // null = Sem estilo
      // true = Campo fica verde
      return this.errorsBag.has(this.nomeCampoErro()) ? false : null
    },
    retornaMensagemDeErro() {
      if (!this.errorsBag) {
        return ''
      }

      return this.errorsBag.first(this.nomeCampoErro())
    },
  }
}

export default inputBase