<template>
  <div>
    <loading
      :active.sync="dados.isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#f68b29"
    />
    <b-form
      class="filtro"
      @submit.prevent="processarInventario(dados.tipoNota)"
    >
      <InputBasico
        ref="refMesInventario"
        label="Data final"
        name="mesInventario"
        v-model="dados.dataFinal"
        :disabled="dados.disabledCampos"
        v-if="dados.visivel"
        type="date"
        min="1"
        max="12"
        class="inputDataFinal"
      />
      <InputSelect
        ref="refFilial"
        label="Código da filial"
        name="filial"
        :options="getFiliais()"
        :class="dados.visivel ? 'inputFilialEmpresa' : 'inputDataFinal'"
        v-model="dados.filialEmpresa"
        :disabled="dados.disabledCampos"
      />
      <div class="produtoCard">
        <!-- <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button> -->
        <b-form-checkbox
          v-model="dados.checked"
          name="check-button"
          switch
          :disabled="dados.disabledCampos"
        >
          Inventário por produto
        </b-form-checkbox>
        <b-collapse id="collapse-1" v-model="dados.checked" class="clpDoc">
          <InputBasico
            ref="refProduto"
            label="Código do produto"
            name="produtoInventario"
            v-model="dados.produtoInventario"
            :disabled="dados.disabledCampos"
            type="text"
            class="inputProdutoInventario"
          />
        </b-collapse>
      </div>

      <b-button
        type="submit"
        variant="primary"
        class="btnProcessar"
        :disabled="dados.disabledCampos"
        >Processar</b-button
      >
      <label v-if="dados.isConcluido" class="concluido loaderCheck"
        >Concluído com sucesso!</label
      >

      <div class="processarCard">
        <b-card no-body >
          <b-card-header header-tag="header" class="p-1" role="tab">
            Itens para processar
          </b-card-header>
          
            <b-card-body>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="dados.selecionados"
                :options="options"
                name="flavour-1"
              ></b-form-checkbox-group>
            </b-card-body>
        </b-card>
      </div>
      <div class="loader" v-if="dados.show_loader_erp">
        <b-spinner
          v-if="!dados.checked_erp"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."
        ></b-spinner>
        <v-icon
          v-if="dados.checked_erp"
          scale="2"
          name="check"
          class="loaderCheck"
        />
        <label class="concluido">Carregando dados ERP...</label>
      </div>
      <div class="loader" v-if="dados.show_loader_produto">
        <b-spinner
          v-if="!dados.checked_produto"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."
        ></b-spinner>
        <v-icon
          v-if="dados.checked_produto"
          scale="2"
          name="check"
          class="loaderCheck"
        />
        <label class="concluido">Carregando produtos...</label>
      </div>
      <div class="loader" v-if="dados.show_loader_cliente">
        <b-spinner
          v-if="!dados.checked_cliente"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."
        ></b-spinner>
        <v-icon
          v-if="dados.checked_cliente"
          scale="2"
          name="check"
          class="loaderCheck"
        />
        <label class="concluido">Carregando clientes...</label>
      </div>
      <div class="loader" v-if="dados.show_loader_fornecedor">
        <b-spinner
          v-if="!dados.checked_fornecedor"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."
        ></b-spinner>
        <v-icon
          v-if="dados.checked_fornecedor"
          scale="2"
          name="check"
          class="loaderCheck"
        />
        <label class="concluido">Carregando fornecedores...</label>
      </div>
      <div class="loader" v-if="dados.show_loader_inventario">
        <b-spinner
          v-if="!dados.checked_inventario"
          class="text-center justify-content-between"
          variant="warning"
          label="Loading..."
        ></b-spinner>
        <v-icon
          v-if="dados.checked_inventario"
          scale="2"
          name="check"
          class="loaderCheck"
        />
        <label class="concluido">Processando o invetário...</label>
      </div>
      <div>
        <b-table
          striped
          hover
          bordered
          head-variant="dark"
          v-if="dados.itemProcessamento"
          :items="dados.itemProcessamento.gridRetornoMateriais.retornoProduto"
          :fields="dados.fieldsProdutos"
        ></b-table>
      </div>
      <div>
        <b-table
          striped
          hover
          bordered
          head-variant="dark"
          stacked="md"
          v-if="dados.itemProcessamento"
          :items="dados.itemProcessamento.gridRetornoInventario"
          :fields="dados.fieldsInventario"
        >
        </b-table>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  processaInventario,
  getListaProcessamentos,
} from "../../../common/services/inventarioService";

import InputBasico from "@/components/inputs/InputBasico";
import InputSelect from "@/components/inputs/InputSelect";
import { getUserObj } from "@/common/localStorage/localStorage";
import * as dataHelper from '@/common/helpers/DataHelper'

export default {
  data() {
    return {
      dados: {
        dataFinal: "",
        produtoInventario: "",
        filialEmpresa: "",
        checked: false,
        checked_fornecedor: false,
        checked_cliente: false,
        checked_produto: false,
        checked_inventario: false,
        show_loader_erp: false,
        show_loader_fornecedor: false,
        show_loader_cliente: false,
        show_loader_produto: false,
        show_loader_inventario: false,
        isConcluido: false,
        disabledCampos: false,
        itemProcessamento: null,
        visivel: getUserObj().erp.tipo && getUserObj().erp.tipo != 'INFORLN',
        selecionados: [
          'materiais',
          'inventario'
        ],
        key: false,
        isLoading: true,
        fieldsProdutos: [
          { key: "codEmp", label: "Código empresa", sortable: true },
          { key: "codPro", label: "Código produto", sortable: true },
          { key: "desRet", label: "Descrição retorno", sortable: true },
        ],
        fieldsInventario: [
          {
            key: "gridRetorno.codEmp",
            label: "Código empresa",
            sortable: true,
          },
          { key: "gridRetorno.codFil", label: "Código filial", sortable: true },
          { key: "gridRetorno.codPro", label: "Produto", sortable: true },
          { key: "gridRetorno.codDer", label: "Derivação", sortable: true },
          { key: "gridRetorno.codDep", label: "Depósito", sortable: true },
          { key: "gridRetorno.mesAno", label: "Mês e Ano", sortable: true },
          { key: "gridRetorno.msgRet", label: "Msg retorno", sortable: true },
        ],
      },
      options: [
          { text: 'Materiais', value: 'materiais' },
          { text: 'Inventário', value: 'inventario' }
      ],
    };
  },
  components: {
    InputBasico,
    InputSelect,
  },
  created: async function() {
    await this.getListaProcessamentos()

    if (!this.dados.visivel) {
      this.dados.dataFinal = dataHelper.atualDateYYYYMMDD('-');
    }
  },
  sockets: {
    ALTERAR_FORM_INVENTARIO: async function (data) {
      this.dados.disabledCampos = true;
      Object.keys(data).forEach((key) => {
        this.dados[key] = data[key];
      });
    },
    HABILITAR_FORM_INVENTARIO: async function (data) {
      this.dados.disabledCampos = false;
    },
    RETORNO_PROCESSAMENTO_FORM_INVENTARIO: async function (data) {
      // console.log(data)
      this.dados.itemProcessamento = data
      this.$validator.reset()
      this.errors.clear()
    }
  },
  methods: {
    getFiliais() {
      let filiais = [
        {
          codFilial: "Todas",
          codFil: "Todas",
          codDep: "TODAS",
          codFiltro: "",
          status: true,
        },
      ];
      filiais.push(...getUserObj().filiais);
      return filiais.map((item) => {
        return { text: `${item.codFil}`, value: `${item.codFilial}` };
      });
    },
    montaDadosEnvio() {
      let retorno = {
        date_high: this.dados.dataFinal,
        branch: this.dados.filialEmpresa,
        listaProcessar: this.dados.selecionados
      };
      if (this.dados.checked) {
        retorno.material = [
          {
            codempresa: getUserObj().empresa.idSAP,
            codfilial: this.dados.filialEmpresa,
            produto: this.dados.produtoInventario,
          },
        ];
      }
      return retorno;
    },
    async processarInventario() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let ret = await processaInventario(this.montaDadosEnvio());
          this.dados.isLoading = false;
          this.key = !this.key;
        }
      });
    },
    async getListaProcessamentos() {
      let r = (await getListaProcessamentos(1)).data
      this.dados.itemProcessamento = r[0]
      // console.log(this.dados.itemProcessamento)
      this.dados.isLoading = false
      this.key = !this.key
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

.filtro {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $medium-size) {
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.8em;
  }

  //   @media (min-width: $extra-large-size) {
  //     width: $medium-size;
  //   }
}
.clpDoc {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $medium-size) {
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.8em;
  }

  //   @media (min-width: $extra-large-size) {
  //     width: $medium-size;
  //   }
}

.processarCard {
    @media (min-width: $medium-size) {
        grid-column: 1 / 6;
        padding-bottom: 1.5rem;
    }
}

.input {
  &DataFinal {
    @media (min-width: $medium-size) {
      grid-column: 1 / 4;
    }
  }
  &ProdutoInventario {
    @media (min-width: $medium-size) {
      grid-column: 1 / 4;
    }
  }
  &FilialEmpresa {
    @media (min-width: $medium-size) {
      grid-column: 4 / 8;
    }
  } 
}

.btn {
  &Processar {
    @media (min-width: $medium-size) {
      grid-column: 1 / 3;
      margin-top: 1.9rem;
      margin-bottom: 1.9rem;
    }
  }
}
.concluido {
  @media (min-width: $medium-size) {
    grid-column: 3 / -1;
    align-self: center;
    padding-left: 0.5rem;
  }
}

.loader {
  @media (min-width: $medium-size) {
    grid-column: 1 / -1;
    align-self: center;
  }
  &Spinner {
    align-self: center;
  }
  &Check {
    color: #00cf2d;
  }
}
.produtoCard {
  @media (min-width: $medium-size) {
    grid-column: 1 / -1;
  }
}
</style>