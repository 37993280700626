<template>
  <LayoutTelas id="tela__veiculos" :titulo="this.$route.meta.titulo">
    <div class="about">
      <p>Esta é a página do inventário</p>
    </div>
    <InventarioSearch/>
    
  </LayoutTelas>
  
</template>

<script>
import InventarioSearch from "./forms/InventarioSearch";

export default {
  data() {
    return {
      dataInventario: '',
    }
  },
  components: {
    InventarioSearch
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

#tela__veiculos {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media (min-width: $medium-size) {
    min-width: $small-size;
  }
}

.paginacao {
  display: flex;
  justify-content: flex-end;

  &__seletor {
    margin: 0;
  }
}

.mesInventario {
  
}

.anoInventario {

}
</style>